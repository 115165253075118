<template>
  <textarea
      :disabled="true"
      :style="'height:' + getHeight"
      ref="textarea"
      id="log_textarea"
      v-model="new_value"
      :placeholder="$store.getters.translate('log')"
      @click="setFocus()"/>
  <v-text-field
      class="custom-text-field"
      variant="underlined"
      color="primary"
      :disabled="!canEdit()"
      :placeholder="$store.getters.translate('add_to_log')"
      single-line
      density="compact"
      v-model="add_line"
      @keyup.enter="insertContent"
      hide-details
      ref="add_line">
    <template #append>
      <v-icon @click="canEdit() && insertContent()" :style="{ cursor: canEdit() ? 'pointer' : 'default', color: canEdit() ? 'primary' : 'grey' }" icon="mdi-plus"/>
    </template>
  </v-text-field>
</template>

<script>
import moment from "moment";
import {useAbility} from "@casl/vue";

export default {
  props: ["model_type", "model_id", "height"],
  setup: function() {
    const { can } = useAbility();
    return {
      can
    }
  },
  data() {
    return {
      new_value: null,
      add_line: null,
      set_first_focus: true,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    canEdit() {
      return this.can("edit", this.$createEntity(this.model_type, {id : this.model_id}));
    },
    canDelete() {
      return this.can("delete", this.$createEntity(this.model_type, {id : this.model_id}));
    },
    load() {
      if(this.model_id) {
        this.$http
            .get(this.$store.getters.appUrl + "v2/model-logs?model_type=" + this.model_type + "&model_id=" + this.model_id)
            .then((response) => {
              this.new_value = response.data;
            })
            .catch((error) => {
              if (this.$store.getters.isLoggedIn) {
                this.$toast.error(error.message);
              }
              this.loading = false;
            });
      }
    },
    save() {
      this.$http
        .post(this.$store.getters.appUrl + "v2/model-logs", {
          model_type: this.model_type,
          model_id: this.model_id,
          log: this.new_value,
        })
        .then(() => {
          this.$emit("update", this.new_value);
          this.load();
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toast.error(error.response?.data?.error ?? error.message);
          }
          this.loading = false;
        });
    },
    setFocus() {
      if (this.set_first_focus) {
        this.$refs.add_line.focus();
        this.set_first_focus = false;
      }
    },
    insertContent() {
      if (this.add_line) {
        let line = "[" + moment().format("DD-MM-YYYY HH:mm:ss") + " - " + this.$store.getters.getUser.person.casual_name + "] " + this.add_line;
        if (!this.new_value) {
          this.new_value = line;
        } else {
          this.new_value = line + "\n" + this.new_value;
        }
        this.add_line = null;
        this.save();
      }
    },
  },
  computed: {
    getHeight() {
      if (this.height) {
        return this.height;
      }
      return "150px";
    },
  },
  watch: {
    model_id: {
      handler() {
        this.load();
      },
    },
  },
};
</script>

<style scoped>
textarea {
  padding: 5px;
  border: 1px solid #9E9E9E;
  border-radius: 5px;
  width: 100%;
  font-size: 12px !important;
  margin-bottom: 0 !important;
  resize: none;
}

textarea:focus {
  outline: none !important;
}

input {
  padding: 0 !important;
}

.custom-text-field {
  padding: 5px;
  border: 1px solid #9E9E9E;
  border-radius: 5px;
  width: 100%;
  font-size: 12px !important;
  margin-top: 5px !important;
  margin-bottom: 15px;
}
</style>