<template>
    <v-row>
      <v-col class="d-flex justify-center">
        <v-progress-circular v-if="loading" :size="75" color="primary" indeterminate/>
        <div v-else class="user mt-2">
          <template v-if="person && person.file">
            <v-img @click="$refs.FileInput.click()" :src="person.file.full_path" class="profile-img"/>
            <v-icon @click="deleteFile()" class="icon bg-primary text-white" icon="mdi-close" size="x-large"/>
          </template>
          <v-img v-else @click="$refs.FileInput.click()" src="../../../assets/images/no-user.jpg" class="profile-img"/>
          <input @change="onFileSelect" ref="FileInput" type="file" style="display: none"/>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="person">
      <v-col style="text-align: center" class="mr-5 ml-5">
        <h3>{{ person.name }}</h3>
        <small v-if="person.user && person.user.last_login">
          {{ $store.getters.translate("last_login") }}:
          {{ moment(person.user.last_login).format("DD-MM-YYYY H:m") }}
        </small>
        <br v-if="person.user && person.user.last_login" />
        <v-chip
            v-if="person.mobilenumber"
            class="mt-2 mr-2"
            label
            variant="outlined"
            size="small"
            color="secondary">
          <v-icon size="small" start icon="mdi-cellphone"/>
          {{ person.mobilenumber }}
        </v-chip>
        <v-chip
            v-if="person.phonenumber"
            class="mt-2 mr-2"
            label
            variant="outlined"
            size="small"
            color="secondary">
          <v-icon size="small" start icon="mdi-phone"/>
          {{ person.phonenumber }}
        </v-chip>
        <v-chip
            v-if="person.user && person.user.email"
            class="mt-2 mr-2"
            label
            size="small"
            color="success">
          <v-icon size="small" start icon="mdi-email"/>
          {{ person.user.email }}
        </v-chip>
        <template v-for="(email, index) in person.emailaddresses">
          <v-chip
              v-if="!person.user || email.emailaddress !== person.user.email"
              :key="index"
              class="mt-2 mr-2"
              label
              size="small"
              color="secondary">
            <v-icon size="small" start icon="mdi-email"/>
            {{ email.emailaddress }}
          </v-chip>
        </template>
      </v-col>
    </v-row>
    <BaseDraggableModal
        name="base_profile_photo"
        ref="base_profile_photo"
        :fullscreen="$vuetify.display.xsOnly"
        max-height="80vh"
        width="70vw">
      <v-card :disabled="loading" :loading="loading">
        <BaseModal>
          <template #header>
            <v-toolbar class="draggable_selector" color="primary" dense flat>
              <v-toolbar-title>{{ $store.getters.translate("edit_avatar") }}</v-toolbar-title>
              <v-spacer/>
              <v-btn size="small" @click="closeModal('base_profile_photo')">
                <v-icon icon="mdi-close" size="x-large"/>
              </v-btn>
            </v-toolbar>
          </template>
          <template #content>
            <v-card-text class="ml-5 mr-5">
              <vue-cropper
                  v-show="selectedFile"
                  ref="cropper"
                  :src="selectedFile"
                  alt="Source Image"/>
            </v-card-text>
          </template>
          <template #footer>
            <v-card-actions>
              <v-btn class="bg-primary" @click="saveFile(); closeModal('base_profile_photo')">{{ $store.getters.translate("save") }}</v-btn>
              <v-btn color="primary" variant="text" @click="closeModal('base_profile_photo')">{{ $store.getters.translate("cancel") }}</v-btn>
            </v-card-actions>
          </template>
        </BaseModal>
      </v-card>
    </BaseDraggableModal>
</template>

<script>
import BaseModal from "./BaseModal";
import BaseDraggableModal from "./BaseDraggableModal";
import helpFunctions from "../../plugins/helpFunctions";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  name: 'BaseProfilePhoto',
  components: {
    VueCropper,
    BaseModal,
    BaseDraggableModal,
  },
  props: ["person"],
  data() {
    return {
      mime_type: "",
      selectedFile: "",
      loading: false,
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    async saveFile() {
      if(!this.loading) {
        this.$refs.cropper.getCroppedCanvas().toBlob(async (blob) => {
          this.loading = true;
          const formData = new FormData();
          formData.append("attachment", blob);
          formData.append("person_id", this.person.id);
          await helpFunctions.saveFile(this.$toast, "people", formData);
          this.$emit("refresh");
          this.loading = false;
        },
        this.mime_type);
      }
    },
    async deleteFile() {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then(async (res) => {
        if (res) {
          this.loading = true;
          await helpFunctions.deleteFile(this.$toast, "people", {person_id: this.person.id});
          this.$emit("refresh");
          this.loading = false;
        }
      });
    },
    onFileSelect(e) {
      if (e.target.files[0]) {
        const file = e.target.files[0];
        this.mime_type = file.type;
        if (typeof FileReader === "function") {
          this.openModal('base_profile_photo');
          const reader = new FileReader();
          reader.onload = (event) => {
            this.selectedFile = event.target.result;
            this.$refs.cropper.replace(this.selectedFile);
          };
          reader.readAsDataURL(file);
        } else {
          alert("Sorry, FileReader API not supported");
        }
      }
    },
  },
};
</script>
<style scoped>
.user {
  width: 125px;
  height: 125px;
  border-radius: 100%;
  border: 3px solid lightgray;
  position: relative;
  text-align: center;
}
.profile-img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  cursor: pointer;
  margin-bottom: 10px;
}
.icon {
  position: absolute;
  top: 10px;
  right: 0;
  background: #e2e2e2;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  text-align: center;
  color: #0000ff;
  font-size: 14px;
  cursor: pointer;
}
</style>