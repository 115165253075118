<template>
  <div>
    <v-btn size="large" variant="text" color="primary" @click="toggleSpace">IMPORT</v-btn>
    <v-dialog v-model="showSpace">
        <Space />
    </v-dialog>
  </div>
</template>

<script>
import { initializeFlatfile } from '@flatfile/vue';
export default {
  components: {Space: {}},
  props: ["title", "fields"],
  data() {
    return {
      results: [],
      showSpace: false,
      spaceProps: {
        name: this.title,
        environmentId: process.env.VUE_APP_FLATFILE_ENVIRONMENT,
        publishableKey: process.env.VUE_APP_FLATFILE_PUBLISHABLE_KEY,
        sheet: this.fields,
        onSubmit: async ({ job, sheet }) => {
          const data = await sheet.allData();
          this.results = data.records.map(record => {
            const transformed = {};
            Object.keys(record.values).forEach(key => {
                transformed[key] = record.values[key].value;
            });
            return transformed;
          });
          this.import(this.results);
        },
        onRecordHook: (record) => {
          const equipment_type = record.get('equipment_type');
          if (equipment_type) {
            record.set('equipment_type', equipment_type.toLowerCase());
          }
          return record;
        },
        closeSpace: {
          operation: 'submitActionFg',
          onClose: () => { this.showSpace = false; },
        },
        themeConfig: { primaryColor: "#546a76", textColor: "#fff" },
        spaceBody: {
          namespace: "red",
        },
      },
    };
  },
  methods: {
    toggleSpace() {
      this.showSpace = !this.showSpace;
      this.OpenEmbed();
    },
    import(results) {
      this.$emit("result", JSON.parse(JSON.stringify(results, null , 2)));
      this.showSpace = false;
    },
  },
  created() {
    const { Space, OpenEmbed } = initializeFlatfile(this.spaceProps);
    this.$options.components.Space = Space;
    this.OpenEmbed = OpenEmbed;
  },
};
</script>
