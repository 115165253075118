<template>
  <BaseDraggableModal
      name="base_report"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.display.xsOnly"
      ref="base_report">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <template #header>
          <v-toolbar class="draggable_selector" color="primary" dense flat>
            <v-btn size="small" @click="closeModal('base_report')">
              <v-icon icon="mdi-close" size="x-large"/>
            </v-btn>
            <v-toolbar-title>{{ $store.getters.translate("progress_report") }} {{ moment().format("DD-MM-YYYY") }}</v-toolbar-title>
            <v-spacer/>
            <v-btn size="small" @click="downloadReport">
              <v-icon icon="mdi-download" size="x-large"/>
            </v-btn>
          </v-toolbar>
        </template>
        <template #content>
            <v-card-text class="ml-5 mr-5">
              <v-row>
                <v-col>{{ $store.getters.translate("root_asset_name").toUpperCase() }}:</v-col>
                <v-col>{{ record.root_asset_name }}</v-col>
              </v-row>
              <v-row>
                <v-col>{{ $store.getters.translate("project_name").toUpperCase() }}:</v-col>
                <v-col>{{ record.name }}</v-col>
              </v-row>
              <v-row>
                <v-col>{{ $store.getters.translate("documents_no").toUpperCase() }}:</v-col>
                <v-col>{{ documentNo }}</v-col>
              </v-row>
              <v-row>
                <v-col>{{ $store.getters.translate("tags").toUpperCase() }}:</v-col>
                <v-col>{{ record.tags?.join(", ") }}</v-col>
              </v-row>
              <v-row>
                <v-col>
                  {{ $store.getters.translate("filter_by_tags").toUpperCase() }}:
                  <v-radio-group v-model="selected_stats_tag" v-on:update:model-value="updateStats" inline>
                    <v-radio color="primary" v-for="(value2, key, index) in all_stats" :value="key" :label="key" :key="index" small/>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row class="mt-0 mb-0">
                <v-col class="pt-0">{{ $store.getters.translate('progress') }}: {{ Math.floor((stats_rows.total_commissioned * 100) / stats_rows.total_amount) }}%</v-col>
                <v-col class="pt-0">{{ $store.getters.translate('nr_documents') }}: {{ stats_rows.total_amount }}</v-col>
                <v-col class="pt-0">{{ $store.getters.translate('nr_commissioned') }}: {{ stats_rows.total_commissioned }}</v-col>
              </v-row>
              <v-row class="mt-0 mb-0">
                <v-col class="pt-0">
                  <v-text-field
                      color="primary"
                      variant="underlined"
                      :label="$store.getters.translate('remarks')"
                      v-model="record.remarks"/>
                </v-col>
              </v-row>
              <v-row dense v-for="(item, type, index) in stats_rows" :key="index">
                <v-col v-if="type !== 'total_amount' && type !== 'total_commissioned' && type !== 'total_status_not_set' &&
                                  type !== 'total_assembled' && type !== 'total_disassembled' && type !== 'total_tightened' && type !== 'total_n_a'">
                  <v-row dense v-for="(value, subtype, index) in item" :key="index">
                    <v-col>
                      <v-row>
                        <v-col>
                          {{ type }} > {{ subtype }}
                          <v-spacer/>
                          <small>{{ value.commissioned }} commissioned - {{ value.amount }} total</small>
                        </v-col>
                      </v-row>
                      <v-row class="mt-0 mb-0">
                        <v-col class="pt-0">
                          <v-progress-linear :model-value="calculatePercentage(value)" color="#45a164" height="45" lighten bg-opacity="0.4">
                            <template v-slot:default="{ value }">
                              <small style="color: white">{{ Math.floor(value) }}%</small>
                            </template>
                          </v-progress-linear>
                        </v-col>
                      </v-row>
                      <template v-if="type === 'Certificates' && subtype === 'Certificate'">
                        <!--TIGHTEDED-->
                        <v-row class="mt-0">
                          <v-col>
                            {{ $store.getters.translate('flange_tightened') }}
                            <v-spacer/>
                            <small>{{ stats_rows['total_tightened'] }} {{ $store.getters.translate('tightened') }} - {{ value.amount }} {{ $store.getters.translate('total') }}</small>
                          </v-col>
                        </v-row>
                        <v-row class="mt-0 mb-0">
                          <v-col class="pt-0">
                            <v-progress-linear :model-value="calculateFlangePercentage(stats_rows['total_tightened'], value.amount)" color="#f1c40f" height="45" lighten bg-opacity="0.4">
                              <template v-slot:default="{ value }">
                                <small style="color: white">{{ Math.floor(value) }}%</small>
                              </template>
                            </v-progress-linear>
                          </v-col>
                        </v-row>
                        <!--ASSEMBLED-->
                        <v-row class="mt-0">
                          <v-col>
                            {{ $store.getters.translate('flange_assembled') }}
                            <v-spacer/>
                            <small>{{ stats_rows['total_assembled'] }} {{ $store.getters.translate('assembled') }} - {{ value.amount }} {{ $store.getters.translate('total') }}</small>
                          </v-col>
                        </v-row>
                        <v-row class="mt-0 mb-0">
                          <v-col class="pt-0">
                            <v-progress-linear :model-value="calculateFlangePercentage(stats_rows['total_assembled'], value.amount)" color="#e03e2d" height="45" lighten bg-opacity="0.4">
                              <template v-slot:default="{ value }">
                                <small style="color: white">{{ Math.floor(value) }}%</small>
                              </template>
                            </v-progress-linear>
                          </v-col>
                        </v-row>
                        <!--DISASSEMBLED-->
                        <v-row class="mt-0">
                          <v-col>
                            {{ $store.getters.translate('flange_disassembled') }}
                            <v-spacer/>
                            <small>{{ stats_rows['total_disassembled'] }} {{ $store.getters.translate('disassembled') }} - {{ value.amount }} {{ $store.getters.translate('total') }}</small>
                          </v-col>
                        </v-row>
                        <v-row class="mt-0 mb-0">
                          <v-col class="pt-0">
                            <v-progress-linear :model-value="calculateFlangePercentage(stats_rows['total_disassembled'], value.amount)" color="#3598db" height="45" lighten bg-opacity="0.4">
                              <template v-slot:default="{ value }">
                                <small style="color: white">{{ Math.floor(value) }}%</small>
                              </template>
                            </v-progress-linear>
                          </v-col>
                        </v-row>
                        <!--N/A-->
                        <v-row class="mt-0">
                          <v-col>
                            N/A
                            <v-spacer/>
                            <small>{{ stats_rows['total_n_a'] }} N/A - {{ value.amount }} {{ $store.getters.translate('total') }}</small>
                          </v-col>
                        </v-row>
                        <v-row class="mt-0 mb-0">
                          <v-col class="pt-0">
                            <v-progress-linear :model-value="calculateFlangePercentage(stats_rows['total_n_a'], value.amount)" color="#cc7a00" height="45" lighten bg-opacity="0.4">
                              <template v-slot:default="{ value }">
                                <small style="color: white">{{ Math.floor(value) }}%</small>
                              </template>
                            </v-progress-linear>
                          </v-col>
                        </v-row>
                        <!--NOT SET-->
                        <v-row class="mt-0">
                          <v-col>
                            {{ $store.getters.translate('flange_status_not_set') }}
                            <v-spacer/>
                            <small>{{ stats_rows['total_status_not_set'] }} {{ $store.getters.translate('status_not_set') }} - {{ value.amount }} {{ $store.getters.translate('total') }}</small>
                          </v-col>
                        </v-row>
                        <v-row class="mt-0 mb-0">
                          <v-col class="pt-0">
                            <v-progress-linear :model-value="calculateFlangePercentage(stats_rows['total_status_not_set'], value.amount)" color="#a11203" height="45" lighten bg-opacity="0.4">
                              <template v-slot:default="{ value }">
                                <small style="color: white">{{ Math.floor(value) }}%</small>
                              </template>
                            </v-progress-linear>
                          </v-col>
                        </v-row>
                      </template>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
        </template>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "./BaseModal";
import BaseDraggableModal from "./BaseDraggableModal";

export default {
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      page: helpFunctions.pages.reports,
      loading: false,
      record: {},
      documentNo: '',
      all_stats: [],
      stats_rows: [],
      selected_stats_tag: "with_tags",
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    load(item, loaded_data = null) {
      this.record = item;
      if(this.record.afe_pef != "") {
        this.documentNo = this.record.afe_pef;
      }
      else if(this.record.description != "") {
        let explode = this.record.description.split(" ");
        this.documentNo = explode[0].replace(",", "");
      }
      this.openModal('base_report');
      if(loaded_data) {
        this.all_stats = loaded_data;
        this.stats_rows = this.all_stats["with_tags"];
      }
      else {
        this.loading = true;
        this.$http
            .get(this.$store.getters.appUrl + "v2/projects/project-stats/" + this.record.id)
            .then((response) => {
              this.loading = false;
              this.all_stats = response.data;
              this.stats_rows = this.all_stats["with_tags"];
            }).catch((error) => {
              if (this.$store.getters.isLoggedIn) {
                this.$toast.error(error.message);
              }
              this.loading = false;
            });
      }
    },
    downloadReport() {
      this.loading = true;
      var report_tags = "";
      if(this.selected_stats_tag === "with_tags" || this.selected_stats_tag === "all") {
        report_tags = this.record.tags.join(", ");
      }
      else {
        report_tags = this.selected_stats_tag;
      }
      this.$http
          .post(this.$store.getters.appUrl + "v2/pdf/generate-report", {
            project: this.record,
            stats: this.stats_rows,
            report_tags: report_tags,
            type: 'progress-report',
            document_type: '',
            document_subtype: ''
          }, {
            responseType: "blob",
          }).then((response) => {
            this.loading = false;
            const blob = new Blob([response.data], { type: "application/pdf" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);

            var project_name = this.record.name.replaceAll('"', '');
            project_name = project_name.replaceAll("'", "");
            project_name = project_name.replaceAll('\\', ' ');
            project_name = project_name.replaceAll('/', ' ');
            project_name = project_name.replaceAll('(', ' ');
            project_name = project_name.replaceAll(')', ' ');
            project_name = project_name.replaceAll('   ', ' ');
            project_name = project_name.replaceAll('  ', ' ');

            link.download = "Progress report " + project_name + " " + report_tags + ".pdf";
            link.click();
            URL.revokeObjectURL(link.href);
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toast.error(error.response?.data?.error ?? error.message);
            }
            this.loading = false;
          });
    },
    calculatePercentage(item) {
      return (item.commissioned * 100) / item.amount;
    },
    calculateFlangePercentage(flange_amount, total_amount) {
      return (flange_amount * 100) / total_amount;
    },
    updateStats() {
      this.stats_rows = this.all_stats[this.selected_stats_tag];
    },
  },
};
</script>