<template>
  <v-row>
    <v-col>
      <v-card :loading="loading" :disabled="loading">
        <v-toolbar flat color="white" dense>
          <!--SEARCH-->
          <v-text-field
              class="ml-5 mt-3"
              color="primary"
              variant="underlined"
              density="compact"
              bg-color="white"
              v-model="search"
              v-debounce:230ms.lock="launchSearch"
              @click:clear="clearSearch"
              :label="$store.getters.translate('search')"
              single-line
              hide-details
              clearable
              append-inner-icon="mdi-magnify"/>
          <v-select
              color="primary"
              variant="underlined"
              class="ml-10 mt-3"
              :label="$store.getters.translate('search_in_fields')"
              v-model="options.search_column"
              :items="search_fields"
              item-value="value"
              item-title="text"
              clearable
              @update:model-value="launchSearch"/>
          <!--EXACT SEARCH-->
          <v-switch color="primary" size="x-small" class="ml-10 mt-7" :label="$store.getters.translate('exact_search')" v-model="options.exact_search"/>
          <!--TO DO LIST-->
          <v-btn-toggle v-if="page.name === 'documents'" class="ml-10" v-model="show_from_app">
            <v-btn size="x-small" :value="true">{{ $store.getters.translate("to_do_list") }}</v-btn>
            <v-btn size="x-small" :value="false">{{ $store.getters.translate("all") }}</v-btn>
          </v-btn-toggle>
          <!--RIGHT PART START (BUTTONS)-->
          <v-spacer/>
          <!--EXCEL EXPORT-->
          <download-excel v-if="records.length > 0" style="font-size: 12px; text-transform: uppercase; cursor: pointer;" :data="records" :name="page.name + '.xls'">
            {{ $store.getters.translate("export_to_excel") }}
            <v-icon color="primary" size="large" class="ml-2" icon="mdi-file-excel"/>
          </download-excel>
          <!--DELETE-->
          <v-btn v-if="canDelete() && selected.length > 0" @click.stop="bulkArchive" size="small">
            {{ $store.getters.translate("delete") }} {{ selected.length }} {{ $store.getters.translate(page.name) }}
            <v-icon color="red" size="large" class="ml-2" icon="mdi-trash-can"/>
          </v-btn>
          <!--DOWNLOAD DOCUMENTS-->
          <v-btn v-if="page.name === 'documents' && selected.length > 0" @click.stop="$emit('download', selected)" size="small">
            {{ $store.getters.translate("download") }}
            <v-icon color="primary" size="large" class="ml-2" icon="mdi-download"/>
          </v-btn>
          <!--IMPORT INSPECTION-->
          <v-btn v-if="page.name === 'inspections' && canCreate() && !base_table_options.no_create" @click.stop="$emit('chooseRootAsset')" size="small">
            {{ $store.getters.translate("import") }}
            <v-icon color="primary" size="large" class="ml-2" icon="mdi-import"/>
          </v-btn>
          <!--IMPORT-->
          <!--<base-import v-if="canCreate() && !base_table_options.no_import" @result="importData" :title="page.name"/>-->
          <!--CREATE-->
          <v-btn v-if="canCreate() && !base_table_options.no_create" @click.stop="$emit('create', true)" size="small">
            {{ $store.getters.translate("create") }}
            <v-icon color="primary" size="large" class="ml-2" icon="mdi-plus"/>
          </v-btn>
        </v-toolbar>
        <v-toolbar v-if="page.name === 'articles'" flat color="white" dense>
          <v-select
              color="primary"
              variant="underlined"
              class="ml-5 mt-3"
              :label="$store.getters.translate('root_asset_name')"
              v-model="article_filters.root_asset_id"
              :items="$lodash.sortBy(root_assets, 'text')"
              item-value="value"
              item-title="text"
              clearable
              @update:model-value="showRecords"/>
<!--          <v-select
              color="primary"
              variant="underlined"
              class="ml-5 mt-3"
              :label="$store.getters.translate('tag_number')"
              v-model="article_filters.tag_number"
              :items="$lodash.sortBy(tag_numbers, 'text')"
              item-value="value"
              item-title="text"
              clearable
              @update:model-value="showRecords"/>-->
          <v-select
              color="primary"
              variant="underlined"
              class="ml-5 mt-3"
              :label="$store.getters.translate('brand')"
              v-model="article_filters.brand_id"
              :items="$lodash.sortBy(brands, 'text')"
              item-value="value"
              item-title="text"
              clearable
              @update:model-value="showRecords"/>
          <v-select
              color="primary"
              variant="underlined"
              class="ml-5 mt-3"
              :label="$store.getters.translate('equipment_type')"
              v-model="article_filters.equipment_type_id"
              :items="$lodash.sortBy(equipment_types, 'text')"
              item-value="value"
              item-title="text"
              clearable
              @update:model-value="showRecords"/>
          <v-select
              color="primary"
              variant="underlined"
              class="ml-5 mt-3"
              :label="$store.getters.translate('location')"
              v-model="article_filters.location_id"
              :items="$lodash.sortBy(locations, 'text')"
              item-value="value"
              item-title="text"
              clearable
              @update:model-value="showRecords"/>
          <v-select
              color="primary"
              variant="underlined"
              class="ml-5 mt-3"
              :label="$store.getters.translate('room')"
              v-model="article_filters.room_id"
              :items="$lodash.sortBy(rooms, 'text')"
              item-value="value"
              item-title="text"
              clearable
              @update:model-value="showRecords"/>
          <v-select
              color="primary"
              variant="underlined"
              class="ml-5 mr-5 mt-3"
              :label="$store.getters.translate('inspection')"
              v-model="article_filters.inspection_id"
              :items="$lodash.sortBy(inspections, 'text')"
              item-value="value"
              item-title="text"
              clearable
              @update:model-value="showRecords"/>
        </v-toolbar>
        <v-data-table
            @toggle-select-all="() => { this.selected = !this.selected.length ? this.getRecords.filter(el => this.canDelete(el.id)) : []; }"
            :headers="fields"
            :items="getRecords"
            :loading="loading"
            class="elevation-1"
            item-value="id"
            selectable-key="id"
            return-object
            :show-select="!base_table_options.no_select"
            v-model="selected"
            v-bind="footer_props"
            v-model:items-per-page="options.itemsPerPage"
            v-model:page="options.page"
            @update:currentItems="current = $event">
          <template v-if="!base_table_options.no_tags" #top>
            <v-card flat elevation="0">
              <v-card-text>
                <base-tags :modelValue="tags" :modal_name="page.name" @change="searchByTags"/>
              </v-card-text>
            </v-card>
          </template>
          <!--USUAL TABLE FOR ALL MODELS-->
          <template #body="{ items }">
            <tr v-for="item in items" :key="item.id" @click.ctrl="handleCtrlClick(item)">
              <td v-if="page.name !== 'roles'">
                <v-checkbox color="primary" v-model="selected" :key="item.id" :value="item" @click.shift="handleShiftClick(item)"/>
              </td>
              <td v-for="(key, index) in Object.keys(response_fields)" :key="index" @click.exact="$emit('preview', item)">
                <!--CHECKBOXES/BOOLEAN VALUES-->
                <template v-if="response_fields[key] === 'boolean'">
                  <template v-if="item[key] === '1' || item[key] === 1 || item[key] === 'true' || item[key] === true">1</template>
                  <template v-else>0</template>
                </template>
                <!--DATES-->
                <template v-else-if="response_fields[key] === 'date' && item[key] && (key === 'date_of_birth' || key === 'created_at' || key === 'updated_at')">
                  {{ moment(item[key], "YYYY-MM-DD").format("DD-MM-YYYY") }}
                </template>
                <!--DROPDOWNS-->
                <template v-else-if="(response_fields[key] === 'select_single' || response_fields[key] === 'select_multiple') && item[key]">
                  <template v-if="Array.isArray(item[key])">
                    <template v-if="item[key].length > 1">
                      <v-chip style="height: auto;" class="mr-2" size="x-small" v-for="elem in item[key]" :key="elem">
                        {{ elem }}
                      </v-chip>
                    </template>
                    <template v-else>
                      {{ $lodash.truncate($store.getters.translate(item[key][0]), { length: 40 }) }}
                    </template>
                  </template>
                  <template v-else>
                    {{ $lodash.truncate($store.getters.translate(item[key]), { length: 40 }) }}
                  </template>
                </template>
                <!--LINKS-->
                <template v-else-if="response_fields[key] === 'link'">
                  <a>
                    <v-avatar v-if="key === 'name' && base_table_options.avatar" class="mr-1" size="20">
                      <v-img v-if="item.avatar" style="border: 1px" :src="item.avatar"/>
                      <v-img v-else-if="item.person && item.person.avatar" style="border: 1px" :src="item.person.avatar"/>
                      <v-img v-else style="border: 1px" src="../../../assets/images/no-user.jpg"/>
                    </v-avatar>
                    {{ $lodash.truncate($store.getters.translate(item[key]), { length: 50 }) }}
                  </a>
                </template>
                <!--ID FIELDS-->
                <template v-else-if="key === 'id' || key === 'internal_id'">
                  <a>{{ item[key] }}</a>
                </template>
                <!--TEXT FIELDS-->
                <template v-else>
                  {{ $lodash.truncate($store.getters.translate(item[key]), { length: 40 }) }}
                </template>
              </td>
              <!--ACTION BUTTONS-->
              <td style="text-align: right">
                <v-icon v-if="page.name === 'projects'" @click="viewReport(item)" size="small" class="mr-2" icon="mdi-file-document"/>
                <v-icon v-if="base_table_options.copy && canCreate(item.id || 0)" @click="makeCopy(item)" size="small" class="mr-2" icon="mdi-content-copy"/>
                <v-icon v-if="canEdit(item.id || 0)" @click="$emit('edit', item)" size="small" class="mr-2" icon="mdi-pencil"/>
                <v-icon
                    v-if="canCreatePermission()"
                    @click="showPermissionManager(item)"
                    size="small"
                    class="mr-2"
                    :class="[isCreatePermissionDisabled?'custom-disabled' : '']"
                    icon="mdi-lock-outline"/>
                <v-icon v-if="!base_table_options.no_select && page.name !== 'roles' && canDelete(item.id)" @click="deleteRecord(item.id)" color="red" size="small" class="mr-2" icon="mdi-trash-can"/>
              </td>
            </tr>
            <tr v-if="getRecords.length === 0">
              <td class="text-center text-grey" :colspan="fields.length + 1">
                <span v-if="!loading">{{ $store.getters.translate("nothing_found") }}</span>
                <span v-else>{{ $store.getters.translate("loading") }}</span>
              </td>
            </tr>
          </template>
        </v-data-table>
        <base-report ref="base_report"/>
      </v-card>
    </v-col>
    <manage-by-roles ref="custom_permission_manager" :selected_record="selected_record" @disableManaging="manageCreatePermissionDisabledStatus" @refresh="showRecords()"/>
  </v-row>
</template>

<script>
import BaseImport from "./BaseImport.vue";
import BaseReport from "./BaseReport.vue";
import lodash from "lodash";
import helpFunctions from "../../plugins/helpFunctions.js";
import ManageByRoles from "../../views/permissions/ManageByRoles";
import { useAbility } from '@casl/vue';

export default {
  components: { BaseImport, ManageByRoles, BaseReport },
  props: ["page"],
  setup() {
    const { can } = useAbility()
    return {
      can
    }
  },
  data() {
    return {
      selected_record: null,
      isCreatePermissionDisabled: false,
      loading: false,
      selected: [],
      all_records: [],
      records: [],
      options: {},
      footer_props: helpFunctions.footer_props,
      filterPermissions: {
        display : {
          value: 'all',
          items: [
            {
              label : this.$store.getters.translate('all'),
              text : 'all',
            }, {
              label : this.$store.getters.translate('custom'),
              text : 'custom',
            }, {
              label : this.$store.getters.translate('default'),
              text : 'default',
            },
          ]
        },
        byEntityItem : {
          value: 'all',
          items: [
            {
              label : this.$store.getters.translate('no_filtered'),
              text : 'all',
            }
          ],
          isDisabled : true,
          isLoading : true,
        },
        byUser : {
          value: 'all',
          items: [
            {
              label : this.$store.getters.translate('no_filtered'),
              text : 'all',
            }
          ],
          isDisabled : true,
          isLoading : true,
        },
        byRole : {
          value: 'all',
          items: [
            {
              label : this.$store.getters.translate('no_filtered'),
              text : 'all',
            }
          ],
          isDisabled : true,
          isLoading : true,
        },
      },
      base_table_options: {},
      search: "",
      original_fields: {},
      response_fields: {},
      search_fields: [],
      fields: [],
      tags: [],
      documents_limit: 10,
      show_from_app: false,
      current: [],
      //ARTICLE FILTER VARIABLES
      article_filters: {
        root_asset_id: null,
        //tag_number: null,
        brand_id: null,
        equipment_type_id: null,
        location_id: null,
        room_id: null,
        inspection_id: null
      },
      root_assets: [],
      //tag_numbers: [],
      brands: [],
      equipment_types: [],
      locations: [],
      rooms: [],
      inspections: [],
      inspections_articles: {}
    };
  },
  created() {
    if(helpFunctions.getLocalStorageValue(this.page.name + "_options")) {
      this.options = helpFunctions.getLocalStorageValue(this.page.name + "_options");
    }
    else {
      this.options = helpFunctions.table_options;
    }
    if(helpFunctions.getLocalStorageValue(this.page.name + "_search")) {
      this.search = helpFunctions.getLocalStorageValue(this.page.name + "_search");
    }
    if(helpFunctions.getLocalStorageValue(this.page.name + "_tags")) {
      this.tags = helpFunctions.getLocalStorageValue(this.page.name + "_tags");
    }
    this.base_table_options = helpFunctions.base_table_options[this.page.name];
    if (this.page.name === 'articles') {
      this.loadRootAssets();
      this.loadBrands();
      this.loadEquipmentTypes();
      this.loadLocations();
      this.loadRooms();
      this.loadInspectionsWithArticleIDs();
    }
  },
  methods: {
    load: lodash.debounce(function () {
      this.run();
    }, 100),
    run() {
      this.records = [];
      this.all_records = [];
      this.loading = true;
      if (this.page.name === "documents") {
        if(this.show_from_app) {
          this.loadDocumentsForSign();
        }
        else {
          for (let i = 0; i < this.documents_limit; i++) {
            this.loadDocuments(i);
          }
        }
      }
      this.$http
          .get(this.$store.getters.appUrl + "v2/" + this.page.name + "?source=site")
          .then((response) => {
            if (this.page.name !== "documents") {
              this.loading = false;
              this.all_records = response.data.data;
            }
            /*if (this.page.name === "articles") {
              this.tag_numbers = [];
              this.all_records.forEach((option) => {
                this.tag_numbers.push({
                  value: option.tag_number,
                  text: option.tag_number,
                });
              });
            }*/
            this.original_fields = helpFunctions.replaceIDwithNameBaseTable(response.data.fields);
            this.search_fields = [];
            this.search_fields.push({
              text: this.$store.getters.translate('all_fields'),
              value: 'all',
            });
            if (this.search && this.search.length > 2) {
              this.response_fields["search_score"] = "text_field";
            }
            Object.entries(this.original_fields).forEach(([key, value]) => {
              this.response_fields[key] = value;
              if(key !== '' && key !== 'sort_order' && key !== 'updated_at' && key !== 'created_at' && key !== 'tags') {
                this.search_fields.push({
                  text: this.$store.getters.translate(key),
                  value: key,
                });
              }
            });
            this.fields = this.composeFields(this.response_fields);
            if (this.page.name !== "documents") {
              this.showRecords();
            }
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toast.error(error.message);
            }
            this.loading = false;
          });
    },
    manageCreatePermissionDisabledStatus(isDisabled = true) {
      this.isCreatePermissionDisabled = isDisabled;
    },
    composeFields(fields) {
      let response = [];
      let field_names = Object.keys(fields);
      for (let i = 0; i < field_names.length; i++) {
        response.push({
          title: this.$store.getters.translate(field_names[i]),
          key: field_names[i],
          align: "start",
          fixed: true,
          sortable: true,
          value: field_names[i],
        });
      }
      response.push({
        title: this.$store.getters.translate("actions"),
        sortable: false,
        align: "end",
        fixed: true,
        value: "actions",
      });
      return response;
    },
    showRecords() {
      var sorted_array = [];
      if (this.tags.length > 0) {
        sorted_array = helpFunctions.sortByTagsBase(this.all_records, this.tags);
        if (this.search && this.search.length > 2) {
          sorted_array = helpFunctions.searchInSortedArray(sorted_array, this.response_fields, this.search, this.options.search_column, this.options.exact_search);
        }
      } else if (this.search && this.search.length > 2) {
        sorted_array = helpFunctions.searchInSortedArray(this.all_records, this.response_fields, this.search, this.options.search_column, this.options.exact_search);
      } else {
        sorted_array = this.all_records;
      }
      if (this.page.name === "articles") {
        if (this.article_filters.root_asset_id) {
          sorted_array = sorted_array.filter(item => item.root_asset_id === this.article_filters.root_asset_id);
        }
        /*if (this.article_filters.tag_number) {
          sorted_array = sorted_array.filter(item => item.tag_number === this.article_filters.tag_number);
        }*/
        if (this.article_filters.brand_id) {
          sorted_array = sorted_array.filter(item => item.brand_id === this.article_filters.brand_id);
        }
        if (this.article_filters.equipment_type_id) {
          sorted_array = sorted_array.filter(item => item.equipment_type_id === this.article_filters.equipment_type_id);
        }
        if (this.article_filters.location_id) {
          sorted_array = sorted_array.filter(item => item.location_id === this.article_filters.location_id);
        }
        if (this.article_filters.room_id) {
          sorted_array = sorted_array.filter(item => item.room_id === this.article_filters.room_id);
        }
        if (this.article_filters.inspection_id) {
          sorted_array = sorted_array.filter(item => this.inspections_articles[this.article_filters.inspection_id].includes(item.id));
        }
      }
      this.records = sorted_array.filter(item => this.canShow(item.id));
    },
    clearSearch() {
      this.search = "";
      if (this.all_records && this.all_records.length > 0) {
        delete this.response_fields["search_score"];
        this.fields = this.composeFields(this.response_fields);
        this.showRecords();
      } else {
        this.load();
      }
    },
    launchSearch() {
      if (this.all_records && this.all_records.length > 0) {
        if (this.search && this.search.length > 2) {
          this.response_fields = {};
          this.response_fields["search_score"] = "text_field";
          Object.entries(this.original_fields).forEach(([key, value]) => {
            this.response_fields[key] = value;
          });
        } else {
          delete this.response_fields["search_score"];
        }
        this.fields = this.composeFields(this.response_fields);
        this.showRecords();
      } else {
        this.load();
      }
    },
    handleCtrlClick(item) {
      if(this.selected.find((el) => el.id === item.id)) {
        this.selected = this.selected.filter((el) => el.id !== item.id);
      }
      else {
        this.selected.push(item);
      }
    },
    handleShiftClick(item) {
        if (this.selected.length === 1 || this.selected.length === 2) {
          const IDs = [this.selected[1]?.id ?? item.id, this.selected[0].id];    // @click.shift is triggered before v-model="selected" updates, so we need to form a list of IDs from the item.
          let start = this.current.findIndex(el => el.raw.id === Math.min(...IDs));       // getting the starting element index
          let end = this.current.findIndex(el => el.raw.id === Math.max(...IDs));       //  end element index
          if(start > end) {
            let tmp = end;
            end = start;
            start = tmp;
          }
          for (let i = start; i < end; i++) {
            if (!this.selected.find((el) => el.id === this.current[i].key)) {
              this.selected.push(this.current[i].raw);
            }
          }
        }
    },
    searchByTags(data) {
      this.tags = data;
      helpFunctions.setLocalStorageValue(this.page.name + "_tags", this.tags);
      if (this.all_records && this.all_records.length > 0) {
        this.showRecords();
      } else {
        this.load();
      }
    },
    bulkArchive() {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then((res) => {
        if (res) {
          this.loading = true;
          this.$http
              .post(this.$store.getters.appUrl + "v2/bulk/remove", { model: this.page.model, ids: this.$lodash.map(this.selected, "id") })
              .then(() => {
                this.loading = false;
                this.$toast.success(this.$store.getters.translate("successfully_deleted"));
                this.selected = [];
                this.load();
              }).catch((error) => {
                if (this.$store.getters.isLoggedIn) {
                  this.$toast.error(error.response?.data?.error ?? error.message);
                }
                this.loading = false;
              });
        }
      });
    },
    async loadDocuments(limit) {
      let start = limit * 10000;
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/" + this.page.name + "?source=site" + "&start=" + start)
          .then((response) => {
            if (response.data.data) {
              this.all_records = [...this.all_records, ...response.data.data];
            }
            this.documents_limit--;
            if (this.documents_limit == 0) {
              this.loading = false;
              this.documents_limit = 10;
              if(this.$route.query.id) {
                this.$emit('edit', {id: this.$route.query.id});
              }
              this.showRecords();
            }
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toast.error(error.message);
            }
            this.loading = false;
          });
    },
    async loadDocumentsForSign() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/" + this.page.name + "?source=site" + "&app_docs=true")
          .then((response) => {
            this.loading = false;
            this.all_records = response.data.data;
            this.showRecords();
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toast.error(error.message);
            }
            this.loading = false;
          });
    },
    async loadRootAssets() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/assets?root=true")
          .then((response) => {
            this.loading = false;
            this.root_assets = [];
            response.data.forEach((option) => {
              this.root_assets.push({
                value: option.id,
                text: option.name,
              });
            });
          })
          .catch((error) => {
            this.$toast.error(error.message);
            this.loading = false;
          });
    },
    async loadLocations() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/locations")
          .then((response) => {
            this.loading = false;
            this.locations = [];
            response.data.forEach((option) => {
              this.locations.push({
                value: option.id,
                text: option.name,
              });
            });
          })
          .catch((error) => {
            this.$toast.error(error.message);
            this.loading = false;
          });
    },
    async loadRooms() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/rooms")
          .then((response) => {
            this.loading = false;
            this.rooms = [];
            response.data.forEach((option) => {
              this.rooms.push({
                value: option.id,
                text: option.name,
              });
            });
          })
          .catch((error) => {
            this.$toast.error(error.message);
            this.loading = false;
          });
    },
    async loadBrands() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/brands")
          .then((response) => {
            this.loading = false;
            this.brands = [];
            response.data.forEach((option) => {
              this.brands.push({
                value: option.id,
                text: option.name,
              });
            });
          })
          .catch((error) => {
            this.$toast.error(error.message);
            this.loading = false;
          });
    },
    async loadEquipmentTypes() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/equipmenttypes")
          .then((response) => {
            this.loading = false;
            this.equipment_types = [];
            response.data.forEach((option) => {
              this.equipment_types.push({
                value: option.id,
                text: this.$store.getters.translate(option.name),
              });
            });
          })
          .catch((error) => {
            this.$toast.error(error.message);
            this.loading = false;
          });
    },
    async loadInspectionsWithArticleIDs() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/inspections-with-articles")
          .then((response) => {
            this.loading = false;
            this.inspections = [];
            this.inspections_articles = {};
            response.data.forEach((option) => {
              this.inspections.push({
                value: option.id,
                text: option.gen_project_number,
              });
              this.inspections_articles[option.id] = option.articles;
            });
          })
          .catch((error) => {
            this.$toast.error(error.message);
            this.loading = false;
          });
    },
    deleteRecord(id) {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then((res) => {
        if (res) {
          this.deleteAction(id);
        }
      });
    },
    async deleteAction(id) {
      this.loading = true;
      this.$http
          .delete(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + id)
          .then(() => {
            this.loading = false;
            this.load();
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toast.error(error.message);
            }
            this.loading = false;
          });
    },
    makeCopy(item) {
      this.$confirm(this.$store.getters.translate("are_you_sure")).then((res) => {
        if (res) {
          this.loading = true;
          item.model = this.page.model;
          this.$http
              .post(this.$store.getters.appUrl + "v2/bulk/copy", item)
              .then(() => {
                this.loading = false;
                this.$toast.success(this.$store.getters.translate("success"));
                this.selected = [];
                this.load();
              }).catch((error) => {
                if (this.$store.getters.isLoggedIn) {
                  this.$toast.error(error.response?.data?.error ?? error.message);
                }
                this.loading = false;
              });
        }
      });
    },
    importData(results) {
      this.$http
          .post(this.$store.getters.appUrl + "v2/bulk/import?model=" + this.page.model, { results: results })
          .then(() => {
            this.loading = false;
            this.$toast.success(this.$store.getters.translate("success"));
            this.load();
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toast.error(error.response?.data?.error ?? error.message);
            }
            this.loading = false;
          });
    },
    viewReport(item) {
      this.$refs.base_report.load(item);
    },
    showPermissionManager(record) {
      this.$refs.custom_permission_manager.managerOptions = {
        entityId: record.id,
        entityModel: this.page.model,
        disabled: [
          'entityItems',
          'entities',
        ]
      }

      this.$refs.custom_permission_manager.page.title = this.$store.getters.translate("manage_custom_permissions");
      this.$refs.custom_permission_manager.show = true;
    },
    editPermissionManager(item) {
      this.$refs.custom_permission_manager.reset(null, true);

      this.selected_record = {
        assoc: {
          custom_permission_action_id: 'action',
          specified_for_entity_id: 'entity_id',
          entity: 'entity',
          higher_order: 'higher_order',
          id: 'id',
          specified_for_role_id: 'role_id',
          status: 'status',
          specified_for_user_id: 'user_id',
        },
        fieldOptions: {
          disabled:  []
        },
        record: item,
        isManagerModal : true,
      };

      this.$refs.custom_permission_manager.page.title = this.$store.getters.translate("edit_custom_permission");
    },
    canCreate(id = 0) {
      return this.can("create", this.$createEntity(this.page.model, {id}));
    },
    canShow(id = 0) {
      return this.can("show", this.$createEntity(this.page.model, {id}));
    },
    canEdit(id = 0) {
      return this.can("edit", this.$createEntity(this.page.model, {id}));
    },
    canCreatePermission(id = 0) {
      return this.can("create", this.$createEntity('CustomPermission', {id}));
    },
    canDelete(id = 0) {
      return this.can("delete", this.$createEntity(this.page.model, {id}));
    },
  },
  computed: {
    getRecords() {
      return this.records;
    },
  },
  watch: {
    options: {
      handler() {
        helpFunctions.setLocalStorageValue(this.page.name + "_options", this.options);
        if (this.all_records && this.all_records.length > 0) {
          this.showRecords();
        } else {
          this.load();
        }
      },
      deep: true,
    },
    search: {
      handler() {
        helpFunctions.setLocalStorageValue(this.page.name + "_search", this.search);
        this.options.page = 1;
      },
    },
    filterPermissions: {
      handler() {
        this.options.page = 1;
        this.showRecords();
      },
      deep: true,
    },
    show_from_app: {
      handler() {
        this.load();
      },
    },
  },
};
</script>

<style>
.v-checkbox {
  display: flex;
}
:deep(div.v-table__wrapper) {
  overflow: unset;
}
.custom-disabled {
  pointer-events: none;
  opacity: 0.6;
}
.v-card {
  position: static !important;
  overflow: visible !important;
}
</style>